<template>
    <CoreContainer class="bg-warning-100 overflow-hidden">
        <BaseContainerContent
            :padding="{ vertical: 'large' }"
            class="mon-monroo-world-invitation"
        >
            <div class="relative">
                <div class="mon-monroo-world-invitation__image-box">
                    <!-- TODO: change image -->
                    <CoreImg src="/images/monroo-world-invitation.webp"
                             alt=""
                             class="mon-monroo-world-invitation__image"
                    />
                </div>

                <span class="mon-monroo-world-invitation__highlighted-text">
                    Óóó!
                </span>
            </div>

            <div class="flex flex-col items-start gap-8">
                <div class="flex flex-col">
                    <h2 class="mon-h4 mon-font-regular mb-2.5">
                        {{ $t('monroo_world.section_title') }}
                    </h2>
                    <h3 class="mon-h1 mb-7">
                        {{ $t('monroo_world.invitation_title') }}
                    </h3>
                    <i18n-t keypath="monroo_world.invitation_subtitle"
                            tag="p"
                            class="mon-text-base"
                            scope="global"
                    >
                        <template #br>
                            <br>
                        </template>
                    </i18n-t>
                </div>

                <div class="flex gap-2 gap-x-3 max-md:flex-col md:flex-wrap lg:gap-2.5 lg:gap-x-5">
                    <NewsletterBenefit
                        v-for="(benefit, index) in benefits"
                        :key="index"
                        :label="benefit"
                    />
                </div>

                <BaseUiButton color="primary">
                    {{ $t('monroo_world.entry') }}
                </BaseUiButton>
            </div>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>

const { t } = useI18n()

const benefits = computed<string[]>(() => [
    t('monroo_world.benefit_discounts'),
    t('monroo_world.benefit_limited_products'),
    t('monroo_world.benefit_events'),
    t('monroo_world.benefit_gifts'),
    t('monroo_world.benefit_free_samples'),
])

</script>

<style lang="scss" scoped>

.mon-monroo-world-invitation {
    @include more-than(md) {
        display: grid;
        grid-template-columns: 25% 65%;
        justify-content: space-between;
    }

    @include more-than(xxl) {
        grid-template-columns: 37% 52%;
    }
}

.mon-monroo-world-invitation__image-box {
    width: calc(100% + 1.25rem);
    margin-left: -0.625rem;
    height: 100%;
    position: relative;
    clip-path: ellipse(67% 69% at 50% 30%);
    margin-bottom: 1.375rem;
    margin-top: -3.125rem;

    @include more-than(md) {
        width: calc(100% + 1.625rem);
        margin-left: -1.625rem;
        clip-path: ellipse(100% 100% at 0% 50%);
        margin-bottom: 0;
        margin-top: 0;
    }

    @include more-than(xl) {
        width: calc(100% + 5.25rem);
        margin-left: -5.25rem;
        clip-path: ellipse(100% 130% at 0% 50%);
    }
}

.mon-monroo-world-invitation__image {
    width: 100%;
    max-width: 200%;
    max-height: 22rem;
    object-fit: cover;

    @include more-than(md) {
        max-height: unset;
        position: absolute;
        width: 100%;
        height: 893px;
        margin-top: -215px;
        right: 0;
        object-position: 20% center;
    }

    @include more-than(xxl) {
        width: 46.5vw;
        margin-left: calc(100% - 46.5vw);
        margin-top: -265px;
        object-position: left;
    }
}

.mon-monroo-world-invitation__highlighted-text {
    display: none;
    font-family: $mon-ff-secondary;
    font-size: 16rem;
    line-height: 16rem;
    color: #f0eee0;
    position: absolute;
    bottom: -1rem;
    right: -4.125rem;

    @include more-than(xxl) {
        display: block;
    }
}

</style>
