<template>
    <NuxtLink
        ref="iconLinkEl"
        :to="item.link"
        class="box-border flex w-40 flex-col items-center gap-5"
        :class="{ 'mon-icon-link--hover': isHovered }"
    >
        <div class="bg-primary-100 xxl:size-[10.25rem] flex size-[5.5rem] items-center justify-center rounded-full lg:size-[7.5rem]">
            <img :src="item.imageUrl"
                 alt=""
                 class="size-full max-h-[50%] max-w-[50%] object-contain"
                 loading="lazy"
            >
        </div>
        <UiLink :is-hovered="isHovered">
            {{ item.label }}
        </UiLink>
    </NuxtLink>
</template>

<script lang="ts" setup>

export interface IconLinkItem {
    label: string
    link: string
    imageUrl: string
}

const {
    item,
} = defineProps<{
    item: IconLinkItem
}>()

// card hover handling
const iconLinkEl = useTemplateRef<HTMLDivElement>('iconLinkEl')
const isHovered = useElementHover(iconLinkEl)

</script>

<style lang="scss" scoped>

</style>
