<template>
    <component :is="tag">
        {{ beforeText }}

        <span class="mon-word-switcher" :class="{ [`mon-word-switcher--${type}`]: type }" :style="{ width: containerWidth }">
            <Transition name="slide-up">
                <span v-if="!isAnimating" :key="currentWord" class="absolute inset-x-0 whitespace-nowrap">
                    {{ currentWord }}
                </span>
                <span v-else :key="nextWord" class="absolute inset-x-0 whitespace-nowrap">
                    {{ nextWord }}
                </span>
            </Transition>
        </span>

        <span v-if="afterText" class="block">
            {{ afterText }}
        </span>
    </component>
</template>

<script setup lang="ts">

const {
    tag = 'div',
    text,
    type = 'text',
} = defineProps<{
    tag?: 'div' | 'h2'
    text: string
    type?: 'text' | 'subtitle' | 'heading'
}>()

const {
    beforeText,
    afterText,
    currentWord,
    nextWord,
    isAnimating,
    containerWidth,
} = useWordSwitcher(text)

</script>

<style scoped lang="scss">

.mon-word-switcher {
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
}

.mon-word-switcher--text {
    height: 1.5em;
}

.mon-word-switcher--subtitle {
    height: 1.3em;
}

.mon-word-switcher--heading {
    height: 1.15em;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: transform $mon-trans-time-extra-long $mon-timing, opacity $mon-trans-time-extra-long $mon-timing;
}

.slide-up-enter-from {
    transform: translateY(100%);
    opacity: 0;
}

.slide-up-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
</style>
