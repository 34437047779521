export function useWordSwitcher(text: string) {
    const beforeText = ref<string>('') // Holds the part of the text before the words in brackets
    const afterText = ref<string>('') // Holds the part of the text after the words in brackets
    const words = ref<string[]>([]) // Array of words extracted from within the brackets
    const currentIndex = ref<number>(0) // Tracks the current index of the displayed word
    const isAnimating = ref<boolean>(false) // Boolean indicating if the word switch animation is active

    const currentWord = computed<string>(() => words.value[currentIndex.value] || '') // The current word to display
    const nextWord = computed<string>(() => words.value[(currentIndex.value + 1) % words.value.length] || '') // The next word in the rotation

    // Calculates the container width based on the longest word
    const containerWidth = computed(() => {
        return Math.max(...words.value.map(word => word.length)) + 'ch'
    })

    // Parses the input text and extracts the words from the brackets
    const parseText = () => {
        beforeText.value = text.split('[')[0]?.trim() || ''

        const insideBracketsMatch = text.match(/\[(.*?)\]/)
        words.value = insideBracketsMatch && insideBracketsMatch[1]
            ? insideBracketsMatch[1].split(',').map(item => item.trim())
            : []

        afterText.value = text.split(']')[1]?.trim() || ''
    }

    // Switches the current word to the next word with animation delay
    const switchWord = () => {
        isAnimating.value = true
        setTimeout(() => {
            currentIndex.value = (currentIndex.value + 1) % words.value.length
            isAnimating.value = false
        }, 500)
    }

    // Interval ID for the word switching interval
    let intervalId: NodeJS.Timeout

    parseText()

    // When the component is mounted, start the word switching interval
    onMounted(() => {
        intervalId = setInterval(switchWord, 3000)
    })

    // Clear the interval when the component is unmounted
    onUnmounted(() => {
        clearInterval(intervalId)
    })

    return {
        beforeText,
        afterText,
        currentWord,
        nextWord,
        isAnimating,
        containerWidth,
    }
}
