<template>
    <component :is="to ? NuxtLink : 'div'"
               ref="cardEl"
               :to="to ? to : undefined"
               class="mon-hero-image-card"
    >
        <CoreImg v-if="imageUrl"
                 :src="imageUrl"
                 class="mon-hero-image-card__img"
                 alt=""
        />

        <div class="mon-hero-image-card__content">
            <slot v-bind="slotData" name="title">
                <h3 class="mon-subtitle">
                    {{ title }}
                </h3>
            </slot>
            <slot v-bind="slotData" name="bottom" />
        </div>
    </component>
</template>

<script lang="ts" setup>

import { NuxtLink } from '#components'

const {
    imageUrl,
    title,
    to,
    trackHover,
} = defineProps<{
    imageUrl?: string | null
    title?: string
    to?: string | null
    trackHover?: boolean
}>()

const cardEl = ref<HTMLDivElement | null>(null)
const isHovered = trackHover ? useElementHover(cardEl) : false

const slotData = computed(() => ({
    isHovered: toValue(isHovered),
}))

</script>

<style lang="scss" scoped>

.mon-hero-image-card {
    display: flex;
    flex-direction: column;
}

.mon-hero-image-card__img {
    width: 100%;
    height: 8.125rem;
    object-fit: cover;

    @include more-than(md) {
        height: 11.125rem;
    }

    @include more-than(lg) {
        height: 18.625rem;
    }
}

.mon-hero-image-card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.375rem;
    padding: 0.875rem 0.625rem 1rem;
    flex: 1;

    z-index: 1;

    transition: transform $mon-trans-time-long $mon-timing;

    @include more-than(md) {
        padding: 1.125rem 1.375rem 1.375rem;
    }

    @include more-than(lg) {
        padding: 2.125rem 1.25rem;
    }
}

.mon-hero-image-card__title {
    @include more-than(lg) {
        @include mon-text-subtitle;
    }
}

.mon-hero-image-card__link {
    display: inline-flex;
    align-items: center;
    gap: 0.375rem;
    text-decoration: underline;

    @include mon-text-small;

    @include more-than(lg) {
        @include mon-h5;
        @include mon-font-regular;
    }

    &:hover {
        text-decoration: none;
    }
}


</style>
