<template>
    <div>
        <!--  -------------------------------------------------------------------------------- -->
        <!--  BANNERS  -->
        <BannerComponent v-if="topBanner" :banner="topBanner">
            <template v-if="topBanner.name" #title>
                <UiWordSwitcher tag="h2"
                                type="heading"
                                :text="topBanner.name"
                                class="mon-h1 mb-6 space-x-4 xl:mb-9"
                />
            </template>

            <template #under-button>
                <i18n-t keypath="pages.index.banners.join_monroo"
                        scope="global"
                        tag="span"
                        class="mon-text-small mt-5 inline-block lg:mt-7 xl:mt-9"
                >
                    <template #link>
                        <NuxtLink :to="getThemePath('login')"
                                  class="mon-link"
                        >
                            {{ $t('pages.index.banners.join_link_text') }}
                        </NuxtLink>
                    </template>
                </i18n-t>
            </template>
        </BannerComponent>

        <!--  -------------------------------------------------------------------------------- -->
        <!-- MONROO WORLD  -->
        <CoreContainer v-if="monrooWorldBanners?.length">
            <BaseContainerContent :padding="{ top: 'extra-large' }">
                <i18n-t keypath="pages.index.monroo_world.title"
                        tag="h2"
                        scope="global"
                        class="mon-h3 lg:mb-15 mb-11"
                >
                    <template #link>
                        <a href="/" class="mon-link">
                            {{ $t('pages.index.monroo_world.title_link') }}
                        </a>
                    </template>
                </i18n-t>

                <div class="mon-monroo-world">
                    <CardHeroImage v-for="(banner, index) in monrooWorldBanners"
                                   :key="index"
                                   :image-url="banner.imageUrl"
                                   :title="banner.name!"
                                   :to="banner.url"
                                   :track-hover="true"
                                   :class="{
                                       'bg-[#FFECDA]': index === 0,
                                       'bg-[#EBF2F7]': index === 1,
                                       'bg-[#E7E6EE]': index === 2,
                                       'bg-[#FCF6F3]': index === 3,
                                   }"
                    >
                        <template #bottom="{ isHovered }">
                            <UiLink v-if="banner.url"
                                    :is-hovered="isHovered"
                            >
                                <span class="mon-link" v-html="banner.content" />
                            </UiLink>
                        </template>
                    </CardHeroImage>
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!-- SECTIONS  -->
        <CoreContainer v-if="sections?.length">
            <BaseContainerContent :padding="{ bottom: 'none' }">
                <h2 class="mon-h3 mb-5 lg:mb-10">
                    {{ $t('pages.index.sections.title') }}
                </h2>
            </BaseContainerContent>
            <BaseContainerContent :padding="{ top: 'none', horizontal: 'max-xl:none' }">
                <div class="mon-sections mon-scrollbar overflow-x-auto">
                    <UiIconLink v-for="(section, i) in sections"
                                :key="i"
                                :item="section"
                    />
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!-- PRODUCT SLIDER  -->
        <CoreContainer v-if="false">
            <BaseContainerContent :padding="{ bottom: 'extra-large' }">
                <h2 class="mon-h3 mb-5 md:mb-10">
                    <!-- TODO: replace with product list name? -->
                    {{ $t('pages.index.products.month_sale') }}
                </h2>

                <!-- TODO: products slider -->
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  BANNERS  -->
        <BannerComponent v-if="middleBanner" :banner="middleBanner">
            <template #text>
                <span class="mon-banner__text mon-banner__text--breakable" v-html="middleBanner.content" />
            </template>
        </BannerComponent>

        <!--  -------------------------------------------------------------------------------- -->
        <!-- PRODUCT SLIDER  -->
        <CoreContainer v-if="false">
            <BaseContainerContent :padding="{ top: 'extra-large' }" class="md:flex md:items-center md:gap-5">
                <div class="md:w-1/3">
                    <h2 class="mon-h3 mb-5 md:mb-10">
                        {{ $t('pages.index.products.title') }}
                    </h2>

                    <UiCollapseText expanded-breakpoint="lg" class="pb-8">
                        <p class="mon-text-base lg:max-w-[25.625rem]">
                            {{ $t('pages.index.products.text') }}
                        </p>
                    </UiCollapseText>

                    <BaseUiButton color="primary"
                                  to="/"
                                  class="mt-5 block lg:mt-7"
                    >
                        {{ $t('pages.index.products.link_text') }}
                    </BaseUiButton>
                </div>

                <div class="md:w-2/3">
                    <div class="mon-products-slider mon-scrollbar overflow-x-auto">
                        <!-- TODO: products slider -->
                    </div>
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!-- BRANDS  -->
        <CoreContainer v-if="brands?.length">
            <BaseContainerContent :padding="{ horizontal: 'max-xxl:none' }">
                <div class="mon-scrollbar flex items-center justify-between gap-8 overflow-x-auto px-2.5 md:px-6 lg:gap-12 xl:px-1">
                    <NuxtLink v-for="brand in brands"
                              :key="brand.id!"
                              :to="brand.urls ?? ''"
                    >
                        <CoreImg :src="brand.thumbnailImageUrl"
                                 :alt="brand.name"
                                 :width="200"
                                 loading="lazy"
                        />
                    </NuxtLink>
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!-- REVIEWS  -->
        <CoreContainer v-if="reviews?.length">
            <BaseContainerContent :padding="{ vertical: 'extra-large' }">
                <div class="xxl:w-1/2 xxl:pb-15 mx-auto pb-5 text-center md:w-2/3">
                    <h2 class="mon-h3 mb-9 text-center">
                        {{ $t('pages.index.reviews.title') }}
                    </h2>

                    <ReviewComponent :reviews="reviews" />
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <!--  -------------------------------------------------------------------------------- -->
        <!--  NEWSLETTER  -->
        <SectionMonrooWorldInvitation />

        <!--  ASSISTANT  -->
        <SectionAssistant :padding="{ vertical: 'large' }" :border="false" />

        <!--  ADVANTAGES  -->
        <SectionAdvantages class="border-t-primary-200 border-t border-solid" />
    </div>
</template>

<script lang="ts" setup>
import type { IconLinkItem } from '~/components/ui/UiIconLink.vue'

const { t } = useI18n()
const runtimeConfig = useRuntimeConfig()
const { getThemePath } = useThemePaths()

definePageMeta({
    layout: 'bare',
})

const {
    categoryBrands: categoryBrandsId,
    bannerHpTop: bannerHpTopId,
    bannerHpMiddle: bannerHpMiddleId,
    bannerHpMonrooWorld: bannerHpMonrooWorldId,
    articleCategoryReviews: articleCategoryReviewsId,
} = runtimeConfig.public.id

const [
    { items: banners },
    { items: brands },
    { items: reviews },
] = await Promise.all([
    useBannersApiService()
        .embed([
            BannerModel.EMBED_MOBILE_IMAGE_URL,
            BannerModel.EMBED_IMAGE_URL,
            BannerModel.EMBED_BANNER_TYPE,
        ])
        .whereIn(BannerModel.ATTR_BANNER_TYPE_ID, [
            bannerHpTopId,
            bannerHpMiddleId,
            bannerHpMonrooWorldId,
        ])
        .useGet(),
    useCategoriesApiService()
        .onlyAttrs([
            CategoryModel.ATTR_ID,
            CategoryModel.ATTR_NAME,
        ])
        .embed([
            CategoryModel.EMBED_URLS,
            CategoryModel.EMBED_IMAGE_URL,
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
        ])
        .whereEquals(CategoryModel.ATTR_PARENT_ID, categoryBrandsId)
        .sortByAsc(CategoryModel.ATTR_POSITION)
        .useGet(),
    useArticlesApiService()
        .embed([
            ArticleModel.EMBED_URLS,
            ArticleModel.EMBED_USER,
            ArticleModel.EMBED_IMAGE_URL,
            ArticleModel.EMBED_DEFAULT_ARTICLE_CATEGORY,
            ArticleModel.EMBED_TAGS,
        ])
        .whereEquals(ArticleModel.ATTR_DEFAULT_ARTICLE_CATEGORY_ID, articleCategoryReviewsId)
        .useGet(),
])

const getBannerById = (id: string) => {
    return banners.value?.find((banner) => banner?.bannerType?.id === parseInt(id))
}
const getBannersById = (id: string) => {
    return banners.value?.filter((banner) => banner?.bannerType?.id === parseInt(id))
}

const topBanner = computed(() => getBannerById(bannerHpTopId))
const middleBanner = computed(() => getBannerById(bannerHpMiddleId))
const monrooWorldBanners = computed(() => getBannersById(bannerHpMonrooWorldId))

// TODO: temporary icon, add link
const sections = computed<IconLinkItem[]>(() => [
    {
        label: t('pages.index.sections.skin'),
        link: '/',
        imageUrl: '/images/skin.svg',
    },
    {
        label: t('pages.index.sections.restructuring'),
        link: '/',
        imageUrl: '/images/skin.svg',
    },
    {
        label: t('pages.index.sections.hydration'),
        link: '/',
        imageUrl: '/images/skin.svg',
    },
    {
        label: t('pages.index.sections.nutrition'),
        link: '/',
        imageUrl: '/images/skin.svg',
    },
    {
        label: t('pages.index.sections.salon'),
        link: '/',
        imageUrl: '/images/skin.svg',
    },
])

definePageMeta({
    layout: 'bare',
})

</script>

<style lang="scss" scoped>

.mon-monroo-world {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem 0.625rem;

    @include more-than(xxl) {
        gap: 1.25rem;
        grid-template-columns: repeat(4, 1fr);
    }
}

// ---------------------------------------

.mon-sections {
    display: flex;
    gap: 1.375rem;
    padding-inline: 0.625rem;

    @include more-than(sm) {
        justify-content: space-between;
    }

    @include more-than(md) {
        padding-inline: 1.625rem;
    }

    @include more-than(xl) {
        padding-inline: 0.25rem;
    }
}

// ---------------------------------------

.mon-products-slider {
    width: calc(50vw + 25%);
}

</style>
